import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import thunk from 'redux-thunk';
import rootReducer from './reducers';


const enhancers = [];
const middleware = [thunk];

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['saving', 'logining']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

export default () => {
  const store = createStore(persistedReducer, composedEnhancers);
  store.subscribe(() => {
    console.log("UPDATE STATE", store.getState());
  });
  return { store, persistor: persistStore(store) };
};




