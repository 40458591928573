import {combineReducers} from 'redux'
import backend from '../services/backend';


function updateTask(arr, index, updater) {
  let newArr = arr.slice();
  newArr[index] = updater(newArr[index]);
  return newArr;
}

function tasks(state = [], action) {
  console.log("ACTION", action.type);
  switch (action.type) {
    case "TASKS_SAVE_SUCCESS":
    case "TASKS_FETCH_SUCCESS":
      return action.payload.map((item, index) => {
        item.index = index;
        return item;
      });
    case "TASK_TOOGLE":
      return updateTask(state, action.payload.id, (item) => {
        item.isDirty = true;
        item.completed = action.payload.completed;
        return item;
      });
    case "TASK_COMMENTS":
      return updateTask(state, action.payload.id, (item) => {
        item.isDirty = true;
        item.comentarisdemanteniment = action.payload.comments;
        return item;
      });
    case "LOGOUT":
      return [];
    case "TASKS_FETCH_REQUEST":
    case "TASKS_FETCH_ERROR":
    default:
      return state;
  }
}

function lastUpdated(state = new Date(0), action) {
  switch (action.type) {
    case "TASKS_SAVE_SUCCESS":
    case "TASKS_FETCH_SUCCESS":
      return new Date();
    default:
      return state;
  }
}

function spaces(state = [], action) {
  switch (action.type) {
    case "TASKS_FETCH_SUCCESS":
      const tasks = action.payload;
      let spaces = tasks.map((task) => task.espai.trim()).reduce(function (ant, curr) {
        let counters = ant;
        if (!counters[curr]) {
          counters[curr] = 0;
        }
        counters[curr]++;
        return counters;
      }, {});
      return spaces;
    case "LOGOUT":
      return [];
    case "TASKS_FETCH_REQUEST":
    case "TASKS_FETCH_ERROR":
    default:
      return state
  }
}

function taskView(state = {showInfo: true}, action) {
  switch (action.type) {
    case "TOGGLE_TASK_INFO":
      let {showInfo, ...rest} = state;
      return {showInfo: !showInfo, rest};
    default:
      return state
  }
}

function centers(state = [], action) {
  switch (action.type) {
    case "CENTERS_FETCH_SUCCESS":
      return action.payload;
    case "CENTERS_FETCH_REQUEST":
      return [];
    case "CENTERS_FETCH_ERROR":
    default:
      return state
  }
}

function centersStatus(state = {loading: false, loaded:false }, action) {
  switch (action.type) {
    case "CENTERS_FETCH_SUCCESS":
      return {loading: false, loaded:true };
    case "CENTERS_FETCH_REQUEST":
      return {loading: true, loaded:false };
    case "CENTERS_FETCH_ERROR":
      return {loading: false, loaded:false };
    default:
      return state
  }
}

function shifts(state = [], action) {
  switch (action.type) {
    case "SHIFTS_FETCH_SUCCESS":
      return action.payload;
    case "SHIFTS_FETCH_REQUEST":
      return [];
    case "SHIFTS_FETCH_ERROR":
    default:
      return state
  }
}

function shiftsLoading(state = false, action) {
  switch (action.type) {
    case "SHIFTS_FETCH_ERROR":
    case "SHIFTS_FETCH_SUCCESS":
      return false;
    case "SHIFTS_FETCH_REQUEST":
      return true;
    default:
      return state
  }
}


function user(state = {}, action) {
  switch (action.type) {
    case "persist/REHYDRATE":
      action.payload && backend.setUserToken(action.payload.user);
      return state;
    case "LOGIN_SUCCESS":
      backend.setUserToken(action.payload);
      return action.payload;
    case "LOGIN_REQUEST":
      return {};
    case "LOGOUT":
      backend.removeUserToken();
      return {};
    case "LOGIN_ERROR":
    default:
      return state
  }
}

function logining(state = false, action) {
  switch (action.type) {
    case "LOGIN_REQUEST":
    case "TASKS_FETCH_REQUEST":
      return true;
    case "TASKS_FETCH_SUCCESS":
    case "TASKS_FETCH_ERROR":
    case "LOGIN_ERROR":
      return false;
    default:
      return state;
  }
}

function saving(state = false, action) {
  switch (action.type) {
    case "TASKS_SAVE_REQUEST":
      return true;
    case "TASKS_SAVE_ERROR":
    case "TASKS_SAVE_SUCCESS":
    case "TASKS_PENDING_COMMENTS_ERROR":
      return false;
    default:
      return state
  }
}

function pendingErrors(state = {show: false}, action) {
  switch (action.type) {
    case "SHOW_ERROR":
      return {show: true,
        msg: action.payload
        };
    case "ERROR_OK":
      return {show: false};
    default:
      return state;
  }
}

function periodicitiesColors(state = {}, action) {
  switch (action.type) {
    case "PERIODICITY_FETCH_SUCCESS":
      const reversed = action.payload.slice().reverse();
      let [first, second] = reversed;
      return {
        [first]: 'info',
        [second]: 'warning'
      };
    default:
      return state
  }
}

function periodicities(state = [], action) {
  switch (action.type) {
    case "PERIODICITY_FETCH_SUCCESS":
      return action.payload;
    default:
      return state
  }
}


const root = combineReducers({
  tasks,
  spaces,
  taskView,
  centers,
  shifts,
  user,
  saving,
  logining,
  periodicitiesColors,
  periodicities,
  pendingErrors,
  centersStatus,
  shiftsLoading,
  lastUpdated
});


export default root;


