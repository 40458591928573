import axios from 'axios';

axios.defaults.baseURL = '/api';

/*axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401 && axios.defaults.headers.common['Authorization']) {

  }
  return error;
});*/

function setUserToken(user) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
}

function removeUserToken() {
  delete axios.defaults.headers.common['Authorization'];
}

export default {...axios, setUserToken, removeUserToken};
